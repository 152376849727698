export const colors = {
  red1: '#B94630',
  red1Transparent: 'rgba(185, 70, 48, 0.8)',
  red2: '#EB5757',
  red3: '#E8725C',
  red4: '#FFCFC7',
  red5: '#FFF6F6',
  red6: '#FFF2F2',
  red7: '#9C3B29',
  black: '#000000',
  gray1: '#5D5D5D',
  gray2: '#898989',
  gray3: '#F9F9F9',
  gray4: '#C4C4C4',
  gray5: '#E8E8E8',
  white: '#FFFFFF',
  landlordCardRed: '#F3664B',
  green1: '#68BB59',
};
